// Bootstrap JS
var bootstrap = require('bootstrap');

// Bootstrap plugins
document.addEventListener('DOMContentLoaded', () => {
	// Tooltip
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
  	return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  // Popover
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
  	return new bootstrap.Popover(popoverTriggerEl)
  })
});
